import AiBoarAutocompleteSource from "./sources/ai_boars";
import AnomalyAutocompleteSource from "./sources/anomalies";
import BarnAutocompleteSource from "./sources/barns";
import BreedAutocompleteSource from "./sources/breeds";
import BoarEjaculateAutocompleteSource from "./sources/boar_ejaculates";
import CorporateCustomerAutocompleteSource from "./sources/corporate_customers";
import CullReasonAutocompleteSource from "./sources/cull_reasons";
import CustomerAutocompleteSource from "./sources/customers";
import DeathCauseAutocompleteSource from "./sources/death_causes";
import DiseaseAutocompleteSource from "./sources/diseases";
import DistributorAutocompleteSource from "./sources/distributors";
import DrugAutocompleteSource from "./sources/drugs";
import EnumValueAutocompleteSource from "./sources/enum_values";
import FarmAutocompleteSource from "./sources/farms";
import FeedAutocompleteSource from "./sources/feeds";
import FeederAutocompleteSource from "./sources/feeders";
import GenericAutocompleteSource from "./sources/generic";
import LabelAutocompleteSource from "./sources/labels";
import PolymorphicLocationAutocompleteSource from './sources/polymorphic_locations'
import PenAutocompleteSource from "./sources/pens";
import PigAutocompleteSource from "./sources/pigs";
import RoomAutocompleteSource from "./sources/rooms";
import SlaughterhouseAutocompleteSource from "./sources/slaughterhouses";
import SurveyFormAutocompleteSource from "./sources/survey_forms";
import SurveyFormChoiceAutocompleteSource from "./sources/survey_form_choices";
import TransporterAutocompleteSource from "./sources/transporters";
import UserAutocompleteSource from './sources/users';
import UserAssignableAutocompleteSource from "./sources/user_assignables";
import TimeZoneAutocompleteSource from "./sources/time_zones";

const sources = {
    ai_boars: AiBoarAutocompleteSource,
    anomalies: AnomalyAutocompleteSource,
    barns: BarnAutocompleteSource,
    breeds: BreedAutocompleteSource,
    boar_ejaculates: BoarEjaculateAutocompleteSource,
    corporate_customers: CorporateCustomerAutocompleteSource,
    cull_reasons: CullReasonAutocompleteSource,
    customers: CustomerAutocompleteSource,
    death_causes: DeathCauseAutocompleteSource,
    diseases: DiseaseAutocompleteSource,
    distributors: DistributorAutocompleteSource,
    drugs: DrugAutocompleteSource,
    enum_values: EnumValueAutocompleteSource,
    farms: FarmAutocompleteSource,
    feeds: FeedAutocompleteSource,
    feeders: FeederAutocompleteSource,
    generic: GenericAutocompleteSource,
    labels: LabelAutocompleteSource,
    pens: PenAutocompleteSource,
    pigs: PigAutocompleteSource,
    polymorphic_locations: PolymorphicLocationAutocompleteSource,
    rooms: RoomAutocompleteSource,
    slaughterhouses: SlaughterhouseAutocompleteSource,
    survey_forms: SurveyFormAutocompleteSource,
    survey_form_choices: SurveyFormChoiceAutocompleteSource,
    transporters: TransporterAutocompleteSource,
    users: UserAutocompleteSource,
    user_assignables: UserAssignableAutocompleteSource,
    time_zones: TimeZoneAutocompleteSource
};

class AutocompleteSourceFactory {
    create(name, options) {
        const src = sources[name];
        if (src) {
            return new src(options);
        } else {
            throw `No source found for ${name}`
        }
    }
}

const sourceFactory = new AutocompleteSourceFactory();
export default sourceFactory;