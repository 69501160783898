import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";
import Locale from "../../locale";
import Fuse from "fuse.js";
import TimeZoneDatabase from "../../time_zone_database";

const currentFormattedDateTime = function (date, timeZone) {
        const timeOfDay = new Intl.DateTimeFormat(undefined, {
            timeZone: timeZone,
            timeStyle: "short"
        }).format(date);
        const formattedDate = new Intl.DateTimeFormat(Locale.get() || undefined, {
            timeZone: timeZone,
            month: 'short',
            day: 'numeric'
        }).format(date)
        const localDate = new Intl.DateTimeFormat(Locale.get() || undefined, {
            month: 'short',
            day: 'numeric'
        }).format(date)
        if (formattedDate === localDate) {
            return timeOfDay;
        } else {
            return `${timeOfDay} (${formattedDate})`
        }
    },
    prioritize = function (items, fn) {
        if (items == null || items.length === 0) {
            return [];
        }

        const groups = Object.groupBy(items, (item) => fn.call(items, item) ? "primary" : "secondary");
        if (groups.primary && groups.secondary) {
            return groups.primary.concat(groups.secondary)
        } else if (groups.primary) {
            return groups.primary
        } else {
            return groups.secondary
        }
    },
    fuseOptions = {
        shouldSort: true,
        ignoreLocation: true,
        minMatchCharLength: 3,
        threshold: 0.25,
        keys: [{
            name: 'name',
            weight: 0.25
        }, {
            name: 'alternativeName',
            weight: 0.2
        }, {
            name: 'countryName',
            weight: 0.1
        }, {
            name: 'abbreviation',
            weight: 0.15
        }, {
            name: 'localizedName',
            weight: 0.2
        }, {
            name: 'currentTimeFormat',
            weight: 0.05
        }, {
            name: 'countryCode',
            weight: 0.025
        }, {
            name: 'keywords',
            weight: 0.024 // JS rounding FTW
        }]
    };

export default class TimeZoneAutocompleteSource extends AutocompleteSource {
    currentTime = new Date();

    constructor(options) {
        super('time_zones', {
            keys: ['name'],
            ...options
        });

        const currentLocale = Locale.get();
        const countryDisplayNames = new Intl.DisplayNames(currentLocale || undefined, {type: 'region'})

        this.fuse = new Fuse(TimeZoneDatabase.getAllTimeZones().map(timeZone => {
            // Add localized info to the time zone
            let item = Object.assign({
                countryName: timeZone.countryCode ? countryDisplayNames.of(timeZone.countryCode) : null,
            }, timeZone);
            if (currentLocale && currentLocale !== "en") {
                // Also get the localized "alternativeName"
                const localizedName = new Intl.DateTimeFormat(
                    currentLocale, {timeZone: timeZone.id, timeZoneName: 'longGeneric'}
                ).formatToParts().find(({type}) => type === 'timeZoneName').value;
                if (localizedName && localizedName !== item.alternativeName) {
                    item.alternativeName = [localizedName, item.alternativeName]
                }
            }
            if (this.suggested && this.suggested.length) {
                item.suggested = this.suggested.includes(item.id);
            }

            return item
        }), fuseOptions);
    }

    async fetch(query) {
        // Make sure all results are formatted with the same time
        this.currentTime = new Date();
        const homeCountry = this.getHomeCountry();

        let resultList;
        if (query && query.trim().length) {
            resultList = this.fuse.search(query);
            if (resultList.length > 150) {
                // List the hits from primary timezones first
                resultList = prioritize(resultList, item => item.priority);
            }
        } else {
            // If no query is entered, list all home country, priority, and suggested timezones
            resultList = this.fuse.list.filter(item => {
                return item.priority || item.suggested || (homeCountry && item.countryCode === homeCountry)
            });
        }

        if (homeCountry) {
            // Move results from the home country to the top of the list
            resultList = prioritize(resultList, item => item.countryCode === homeCountry);
        }
        if (this.suggested) {
            // Move suggested results to the top of the list
            resultList = prioritize(resultList, item => item.suggested);
        }
        return resultList;
    }

    getHomeCountry() {
        return this.countryInputSelector ? document.querySelector(this.countryInputSelector)?.value : this.homeCountry;
    }

    styleOptionNode(node, selection) {
        const homeCountry = this.getHomeCountry();
        const labelDiv = document.createElement("div");
        labelDiv.classList.add('text-wrap');
        labelDiv.innerHTML = selection.match;
        if (selection.value.suggested) {
            const badge = document.createElement("span");
            badge.classList.add('badge', 'badge-primary', 'ml-1', 'small');
            badge.textContent = i18n.t('suggested');
            labelDiv.append(badge);
        } else if (homeCountry && selection.value.countryCode === homeCountry) {
            const badge = document.createElement("span");
            badge.classList.add('badge', 'badge-leeo', 'ml-1', 'small');
            badge.textContent = selection.value.countryName;
            labelDiv.append(badge);
        }
        if (this.userTimeZone && this.userTimeZone === selection.value.id) {
            const badge = document.createElement("span");
            badge.classList.add('badge', 'badge-info', 'ml-1', 'small');
            badge.textContent = i18n.t('your_time_zone');
            labelDiv.append(badge);
        }
        node.innerHTML = labelDiv.outerHTML;

        const currentTime = document.createElement("span");
        currentTime.classList.add('small', 'text-muted', 'm-0', 'text-truncate');
        currentTime.textContent = `${currentFormattedDateTime(this.currentTime, selection.value.id)}`
        if (selection.value.currentTimeFormat) {
            currentTime.innerHTML += `, ${selection.value.currentTimeFormat}`;
        }
        node.append(currentTime);
    }

    styleFormattedValue(node, selection) {
        let subtitle = selection.value.localizedName ?? selection.value.alternativeName;
        if (this.showCurrentTime) {
            subtitle += `, ${currentFormattedDateTime(new Date(), selection.value.id)}`;
        }
        node.innerHTML = `<div class="d-flex align-items-center">
      <span class="w-100 text-truncate">${selection.value.name}</span>
      <span class="flex-grow-1 text-muted small mb-0 ml-2">${subtitle}</span>
</div>`;
    }

}
